$blue-color:        #388DF7;
$light-color:       #ffffff;
$dark-color:        #2f2f2f;
$green-color:       #2CEFC0;
$light-gray-color:  #A6A8B3;

.group-nav {
    width: 80%;
    max-width: 960px;
    transform: translateY(120px);
    opacity: 0;
    transition: all .15s ease .35s;
}

.menu-active nav {
  
}

.app-nav-cage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .menu-bg {
        display: inline-block;
        background-color: $dark-color;
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        transition: transform .3s ease;
        transition-delay: .25s;
        will-change: transform;
        margin-top: 31px;
        margin-right: 28px;
    }

    .menu-active & {
        .menu-bg {
            transform: scale(1);
            transition-delay: 0s;
        }
    }
}

.menu-active .group-nav {
    transform: translateY(0);
    transition: all .25s ease;
    opacity: 1;
}

.app:not(.menu-active) .app-nav-cage {
    pointer-events: none;
}

nav {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 50;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    transition: all .25s ease;
    flex-direction: column;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    width: 100%;
}

nav li {
    flex-grow: 1;
    text-align: center;
    border: solid 1px $light-color;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

nav li a {
    color: $light-color;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 10px;
    padding: 16px 0;
    display: inline-block;
    position: relative;
}

nav li a::after {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    height: 6px;
    top: 50%;
    margin-top: -3px;
    transform: scaleX(0);
    transform-origin: center left;
    transition: all .25s ease;
    background-color: $green-color;
}

li a.current::after {
    transform: scale(1);
}

nav .contact-box {
    width: 100%;
    max-width: 960px;
    border: solid 1px $light-color;
    border-top: none;
    text-align: center;
    padding: 24px;
}

nav .contact-box li {
    height: 50px;
}

nav .contact-box .title {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 8px;
    margin-bottom: 24px;
    color: #fff;
}

.group-nav ul {
    flex-direction: column;
}

.group-nav .contact-box ul {
    flex-direction: row;
}

@media only screen and (min-width: 376px) {
    .app-nav-cage {
        .menu-bg {
            margin-top: 59px;
            margin-right: 56px;
        }
    }
}

@media only screen and (min-width: 769px) {
    .group-nav ul {
        flex-direction: row;
    }

    nav .contact-box li {
        height: 60px;
    }

    nav li {
        height: 80px;
    }
}