@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Gentium+Book+Basic:400,700&display=swap');

$blue-color:        #388DF7;
$light-color:       #ffffff;
$dark-color:        #2f2f2f;
$green-color:       #2CEFC0;
$light-gray-color:  #A6A8B3;
$gray-color:        #E6EDEF;
$bg-color:          #F3F7FB;

$main-font: "Roboto","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
$main-font: "Open Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
$second-font: "Gentium Book Basic",Georgia,Cambria,"Times New Roman",Times,serif;

.about {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    position: relative;
    background-color: $bg-color;
    transition: all .25s ease;
}

.about-page-enter, .about-page-enter-active, .about-page-appear, .about-page-appear-active {
    position: absolute;
    opacity: 0;

    .about-text-block {
        transform: translateY(20%);
        opacity: 0;
    }
}

.about-page-exit, .about-page-exit-active {

    .about-text-block {
        transform: translateY(20%);
        opacity: 0;
    }
}

.about-page-enter-done, .about-page-appear-done {
    opacity: 1;

    .about-text-block {
        transform: translateY(0);
        opacity: 1;
        transition-delay: .5s;
    }
}

.about-cage {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    padding: 32px 0;;

    .cage {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.about-cage .half {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.half-right {
        transition: all .25s ease;
    }
}

.scene {
    &.baby {
        width: 140px;
        height: 259px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    &.grown {
        width: 280px;
        height: 363px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
}

.about-text-block {
    width: 90%;
    max-width: 680px;
    margin: auto;
    text-align: center;
    transition: all .25s ease;

    .chapter {
        // color: $light-color;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        line-height: 40px;
        margin-bottom: 32px;
    }

    .chapter-title {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 18px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 30px;

        span {
            font-size: 22px;
            vertical-align: top;
            display: inline-block;
            border-bottom: solid 2px;
            margin-right: 12px;
        }
    }

    .data-content {
        color: $dark-color;
        padding: 0 30px;
        text-align: justify;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.6;
        text-rendering: optimizeLegibility;
        cursor: default;

        &:last-child {
            margin-bottom: 0;
        }

        span, a {
            font-weight: 700;
            text-decoration: none;
        }

        .trigger {
            border-bottom: solid 2px darken($green-color, 10%);
            cursor: pointer;
            transition: all .25s ease;
            color: $dark-color;

            &:hover {
                border-color: $dark-color;
            }
        }
    }

    .title-group {
        position: relative;
        margin-bottom: 32px;
        
        .bg-text {
            color: $green-color;
            text-align: center;
            font-size: 64px;
            font-weight: bold;
            display: inline-block;
            letter-spacing: 14px;
        }
    }
}

.experience-text-block {
    width: 80%;
    max-width: 680px;
    margin: auto;
    text-align: center;
}

.my-tool-tip {
    background-color: $light-color !important;
    box-shadow: 0 0 45px transparentize($dark-color, .9);

    &::after {
        border-top-color: $light-color !important;
    }
}

.stay-on-hover {
    pointer-events: auto !important;
    
    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
    }

    &.my-tool-tip {
        padding: 8px;
    }

    a {
        display: inline-block;
        padding: 4px 12px;
        position: relative;

        svg {
            path {
                fill: $dark-color;
                transition: all .25s ease;
            }
        }

        &:hover {
            opacity: .5;
            svg {
                path {
                    fill: darken($green-color, 20%);
                }
            }
        }
    }

    a + a::before {
        // content: '';
        height: 12px;
        width: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -6px;
        background-color: $light-color;
    }
}

.scene-enter, .scene-exit, .scene-appear {
    .about-text-block, .experience-text-block {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        opacity: 0;
        transform: translateY(-200px);
        transition: all 0.25s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }
}

.scene-enter-done {
    .about-text-block, .experience-text-block {
        position: relative;
        margin-top: 0 !important;
        transform: translateY(0);
        opacity: 1;
        transition: all 0.25s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }
}

@media screen and (min-width: 769px) {
    .about-cage {
        display: flex;
        padding: 0;

        .half {
            overflow: hidden;
        }
    }

    .about-page-enter, .about-page-enter-active, .about-page-appear, .about-page-appear-active {
        .about-cage .half-right {
            background-color: transparent;
        }    
    }

    .about-page-enter-done, .about-page-appear-done {
        .about-cage .half-right {
            background-color: transparentize($dark-color, .98);
            transition-delay: .25s;
        }
    }
}

@media screen and (min-width: 1025px) {
       
}