$blue-color:        #388DF7;
$light-color:       #ffffff;
$dark-color:        #2f2f2f;
$green-color:       #2CEFC0;
$light-gray-color:  #A6A8B3;
$gray-color:        #E6EDEF;
$bg-color:          #F3F7FB;

.home {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 1;
    background-color: $bg-color;
    padding-top: 80px;
    overflow-x: auto;

    .green-bg {
        display: none;
        transition: all .25s ease;
    }
}

@keyframes appearing {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: $blue-color;
    }
}

.home-cage {
    width: 100%;
    // max-width: 1092px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-direction: column;

    .text-box {
        color: #000;
        text-align: left;
        width: 84%;
        transition: all .25s ease;

        .main-title {
            margin-bottom: 2rem;
            line-height: 1.4;
            font-size: 1.6rem;
        }

        .sub-title {
            font-weight: normal;
            line-height: 1.6;
            font-size: 1rem;
            margin-bottom: 3rem;
        }

        .home-nav {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            margin-bottom: 20px;
            flex-direction: column;
            align-items: flex-start;

            li {
                overflow: hidden;

                a {
                    color: #000;
                    font-size: 1rem;
                    display: inline-block;
                    padding-bottom: 8px;
                    position: relative;
                    transition: all .25s ease;

                    &::before, &::after {
                        content: '';
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        transition: all .25s ease;
                    }

                    &::before {
                        background-color: $green-color;
                    }

                    &::after {
                        background-color: darken($green-color, 25%);
                        transform: translateX(-100%);
                    }

                    &:hover {
                        color: darken($green-color, 25%);

                        &::before {
                            transform: translateX(100%);
                        }

                        &::after {
                            transform: translateX(0);
                            transition-delay: .3s;
                        }
                    }
                }

                + li {
                    margin-top: 20px;
                }
            }
        }
    }

    .image-box {
        display: none;
        transition: all .25s ease;
    }
}

.intro-page-enter, .intro-page-appear {
    .text-box {
        transform: translateY(10%);
        opacity: 0;
    }

    &.home {
        .green-bg {
            right: -100%;
        }
    }

    .image-box {
        transform: translateY(-20%);
        opacity: 0;
    }
}

.intro-page-enter, .intro-page-enter-active {
    &.home {
        position: absolute;
    }
}

.intro-page-enter-done, .intro-page-appear-done {
    .text-box {
        transform: translateY(0%);
        opacity: 1;
    }

    &.home {
        .green-bg {
            right: 0;
            transition-delay: .2s;
        }
    }

    .image-box {
        transform: translateY(0);
        opacity: 1;
        transition-delay: .25s;
    }
}

.intro-page-exit, .intro-page-exit-done {
    &.home {
        .green-bg {
            right: 100%;
            transition-duration: .5s;
        }
    }

    .image-box {
        transform: translateY(-20%);
        opacity: 0;
        transition-delay: .25s;
    }

    .text-box {
        transform: translateY(10%);
        opacity: 0;
        transition-delay: .25s;
    }
}



@media only screen and (max-width: 375px) and (min-height: 600px) {
    .home {
        justify-content: flex-end;
        padding-top: 0;
        
        .green-bg {
            position: absolute;
            background-color: $green-color;
            right: 0;
            width: 100%;
            height: 80px;
            display: block;
        }
    }

    .home-cage {
        .image-box {
            display: flex;
            width: 40%;
            justify-content: flex-end;
            align-self: flex-end;
    
            img {
                width: 100%;
                height: 100%;
            }
        }

        .text-box {
            margin-bottom: -30%;
        }
    }
}

@media only screen and (max-width: 375px) and (min-height: 700px) {
    .home {
        justify-content: flex-end;
        padding-top: 0;
        
        .green-bg {
            position: absolute;
            background-color: $green-color;
            right: 0;
            width: 100%;
            height: 80px;
            display: block;
        }
    }

    .home-cage {
        .image-box {
            display: flex;
            width: 40%;
            justify-content: flex-end;
            align-self: flex-end;
    
            img {
                width: 100%;
                height: 100%;
            }
        }

        .text-box {
            margin-bottom: -30%;

            .main-title {
                font-size: 2.1rem;
            }

            .sub-title {
                font-size: 1.2rem;
            }
        }
    }
}

@media only screen and (min-width: 376px) {
    .home {
        justify-content: flex-end;
        padding-top: 0;

        .green-bg {
            position: absolute;
            background-color: $green-color;
            right: 0;
            width: 100%;
            height: 80px;
            display: block;
        }
    }

    .home-cage {
        .text-box {
            margin-bottom: -36%;

            .main-title {
                font-size: 1.9rem;
            }

            .sub-title {
                font-size: 1.2rem;
            }
        }

        .image-box {
            display: flex;
            width: 100%;
            justify-content: flex-end;
    
            img {
                width: 40%;
                height: auto;
            }
        }
    }
}

@media only screen and (min-width: 426px) {
    .home-cage {
        .text-box {
            width: 64%;

            .main-title {
                font-size: 2rem;
            }

            .sub-title {
                margin-bottom: 10rem;
            }
            .home-nav {
                margin-bottom: 40px;

                li {
                    + li {
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 769px) {
    .home {
        .green-bg {
            width: 23%;
            height: 100%;
        }
    }

    .home-cage {
        flex-direction: row;
        width: 75%;

        .text-box {
            width: 40%;
            margin-bottom: 0;
            max-width: 27.5rem;

            .main-title {

            }

            .sub-title {
                margin-bottom: 5rem;
            }

            .home-nav {
                flex-direction: row;

                li {
                    + li {
                        margin-left: 32px;
                        margin-top: 0;
                    }

                    a {
                        font-size: .7rem;
                    }
                }
            }
        }

        .image-box {
            width: 52%;

            img {
                width: 100%;
            }
        }
    }

    .intro-page-enter, .intro-page-appear {
        &.home {
            .green-bg {
                right: -30%;
            }
        }
    }
}

@media only screen and (min-width: 1025px) {
    .home-cage {
        .text-box {
            .main-title {
                font-size: 2.125rem;
            }

            .sub-title {
                font-size: 1.375rem;
            }

            .home-nav {
                li {
                    a {
                        font-size: .875rem;
                    }
                }
            }
        }

        .image-box {
            width: 40%;
        }
    }
}

@media only screen and (min-width: 1921px) {
    .home-cage .text-box {
        max-width: none;
    }
  }