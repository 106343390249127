$blue-color:  #388DF7;
$light-color: #ffffff;
$dark-color:  #2f2f2f;
$green-color:       #2CEFC0;

header {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    top: 0;
  }
  
  header .menu-button {
    position: relative;
    display: flex;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border-color: transparent;
    outline: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      svg path g {
        fill: darken($green-color, 25%);
      }
    }
  }
  
  header .menu-button svg {
    position: relative;
    z-index: 51;
    transition: all 1s ease;
  }

  header .menu-button svg path g {
    transition: all 1s ease;
  }

  header .menu-button .close-icon {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: -11px;
    opacity: 0;
    transform: rotate(-360deg);
  }
  
  header .menu-button .menu-bg {
    position: absolute;
    width: 240px;
    height: 240px;
    left: 50%;
    top: 50%;
    margin-top: -120px;
    margin-left: -120px;
    background-color: transparentize($dark-color, .04);
    border-radius: 50%;
    transform: scale(0);
    transform-origin: center center;
    transition: all .5s ease .35s;
    will-change: transform;
    z-index: 50;
  }
  
  .menu-active header .menu-button .menu-bg {
    transform: scale(1);
    transition: all .5s ease 0s;
  }

  .menu-active header .menu-button .menu-icon {
    transform: rotate(360deg);
    opacity: 0;
  }

  .menu-active header .menu-button .close-icon {
    transform: rotate(0deg);
    opacity: 1;
  }
  
  header .logo {
    z-index: 51;

    &:hover {
      cursor: pointer;
    }
  }

  header .logo svg {
    width: 24px;
    height: auto;
  }

  header svg path {
    transition: all .25s ease;
  }

  .menu-active header svg path {
    fill: $light-color;
  }

  @media only screen and (min-width: 376px) {
    header {
      padding: 32px;
    }

    header .menu-button {
      width: 48px;
      height: 48px;
    }

    header .logo svg {
      width: 40px;
    }
  }