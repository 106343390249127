$blue-color:        #388DF7;
$light-color:       #ffffff;
$dark-color:        #2f2f2f;
$green-color:       #29D08A;
$light-gray-color:  #A6A8B3;
$gray-color:        #E6EDEF;

.about-navi-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    margin: auto;
    width: 90%;
    max-width: 280px;

    .indicator-marker {
        width: 40px;
        height: 40px;
        position: absolute;
        top: -56px;
        perspective: 100px;
        transition: all .25s ease;

        &.rota {
            span {
                transform: rotateY(-180);

                &:last-child {
                    transform: rotateY(0deg);
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: $light-color;
            position: absolute;
            left: 0;
            top: 0;
            backface-visibility: hidden;
            transition: all .5s ease;
            border-radius: 4px;

            &::after {
                content: '';
                width: 10px;
                height: 10px;
                background-color: $light-color;
                display: inline-block;
                bottom: -5px;
                position: absolute;
                transform: rotate(45deg);
                border-radius: 2px
            }

            svg {
                width: 16px;
                height: auto;

                path {
                    fill: lighten($dark-color, 30%);
                }
            }
        }
    }

    &.experience-active {
        .indicator-marker {
            left: 248px;

            span {
                &:first-child {
                    transform: rotateY(-180deg);
                }

                &:last-child {
                    transform: rotateY(0deg);
                }
            }
        }
    }

    &.story-active {
        .indicator-marker {
            left: -8px;

            span {
                &:first-child {
                    transform: rotateY(0deg);
                }

                &:last-child {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

.about-navi {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
        height: 24px;
        width: 24px;
        
        a {
            display: inline-block;
        }

        &.line-connector {
            height: 4px;
            width: 100%;
            flex-grow: 1;
            background-color: transparentize(#fff, .8);
        }
    }

    .marker {
        width: 24px;
        height: 24px;
        background-color: yellow;
        display: inline-block;
        border-radius: 50%;
    }
}