$blue-color:        #388DF7;
$light-color:       #ffffff;
$dark-color:        #2f2f2f;
$green-color:       #2CEFC0;
$light-gray-color:  #A6A8B3;
$gray-color:        #E6EDEF;

$main-font: "Roboto","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
$main-font: "Open Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
$second-font: "Gentium Book Basic",Georgia,Cambria,"Times New Roman",Times,serif;


.fake-browser {
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 64px;

    .browser-body {
        height: 340px;
        overflow: hidden;
        position: relative;
        margin-top: -4px;

        img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0px;
            transition: all 2s ease;
        }

        &:hover {
            img {
                top: -100%;
            }
        }
    }
}

.text-group {
    .part-title {
        font-size: 14px;
        text-align: left;
        display: block;
        color: lighten($dark-color, 20%);
        font-weight: bold;
        margin-bottom: 16px;
    }
}

.img-box {
    &.flex-box {
        display: flex;
        flex-wrap: wrap;

        img {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }
}