@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Gentium+Book+Basic:400,700&display=swap');

$blue-color:        #388DF7;
$light-color:       #ffffff;
$dark-color:        #2f2f2f;
$green-color:       #29D08A;
$light-gray-color:  #A6A8B3;
$gray-color:        #E6EDEF;

%covered {
  width: 100%;
  height: 100%;
}

$main-font: "Roboto","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
// $main-font: "Open Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
$second-font: "Gentium Book Basic",Georgia,Cambria,"Times New Roman",Times,serif;

html {
  @extend %covered;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  @extend html;
  color: #000;
  font-family: $main-font;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.gentium {
  font-family: $second-font;
}

.text-center {
  text-align: center;
}

#root {
  @extend %covered;
  padding: 16px;
}

.app {
  @extend %covered;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: all .5s ease .75s;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

html, body {
  font-size: 14px;
}

.main-cage {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.cage {
  max-width: 816px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .box {
    padding: 16px;
  }

  .box-1, .box-2, .box-3, .box-4, .box-5, .box-6, .box-7, .box-8, .box-9, .box-10, .box-11, .box-12,
  .box-m-1, .box-m-2, .box-m-3, .box-m-4, .box-m-5, .box-m-6, .box-m-7, .box-m-8, .box-m-9, .box-m-10, .box-m-11, .box-m-12,
  .box-l-1, .box-l-2, .box-l-3, .box-l-4, .box-l-5, .box-l-6, .box-l-7, .box-l-8, .box-l-9, .box-l-10, .box-l-11, .box-l-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 376px) {
  #root {
    padding: 24px;
  }
}

@media only screen and (min-width: 426px) {
  .cage {
    .box-m-1 {
      flex: 0 0 8.3333%;
      max-width: 8.3333%;
    }
    .box-m-2 {
      flex: 0 0 16.6667;
      max-width: 16.6667;
    }
    .box-m-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .box-m-4 {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    .box-m-5 {
      flex: 0 0 41.6667%;
      max-width: 41.6667%;
    }
    .box-m-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .box-m-7 {
      flex: 0 0 58.3333%;
      max-width: 58.3333%;
    }
    .box-m-8 {
      flex: 0 0 66.6667%;
      max-width: 66.6667%;
    }
    .box-m-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .box-m-10 {
      flex: 0 0 83.3333%;
      max-width: 83.3333%;
    }
    .box-m-11 {
      flex: 0 0 91.6667%;
      max-width: 91.6667%;
    }
    .box-m-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 1025px) {
  html, body {
    font-size: 14px;
  }

  .cage {
  
    .box-l-1 {
      flex: 0 0 8.3333%;
      max-width: 8.3333%;
    }
    .box-l-2 {
      flex: 0 0 16.6667;
      max-width: 16.6667;
    }
    .box-l-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .box-l-4 {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    .box-l-5 {
      flex: 0 0 41.6667%;
      max-width: 41.6667%;
    }
    .box-l-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .box-l-7 {
      flex: 0 0 58.3333%;
      max-width: 58.3333%;
    }
    .box-l-8 {
      flex: 0 0 66.6667%;
      max-width: 66.6667%;
    }
    .box-l-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .box-l-10 {
      flex: 0 0 83.3333%;
      max-width: 83.3333%;
    }
    .box-l-11 {
      flex: 0 0 91.6667%;
      max-width: 91.6667%;
    }
    .box-l-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 1441px) {
  html, body {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1581px) {
  html, body {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1721px) {
  html, body {
    font-size: 20px;
  }
}

@media only screen and (min-width: 2001px) {
  html, body {
    font-size: 22px;
  }
}