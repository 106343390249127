$dark-color:    #2f2f2f;
$light-color:   #ffffff;
$gray-color:    #ededed;

.mac {
    display: flex;
    flex-direction: column;
    align-items: center;
    perspective: 150px;
    position: relative;
    max-width: 300px;
    transform: translateY(60%);
    animation: jumping cubic-bezier(0.19, 0.49, 0.44, 1.82) 0.25s forwards 1.25s;
}

.shadow {
    position: absolute;
    width: 300px;
    height: 16px;
    background-color: transparentize($dark-color, .8);
    bottom: -6px;
    border-radius: 50%;
    width: 0;
    animation: jumpShadow cubic-bezier(0.19, 0.49, 0.44, 1.82) 0.25s forwards 0.25s, shadowBtm ease 0.5s forwards .75s;
}

.screen {
    background-color: $dark-color;
    width: 300px;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px 15px 0 0;
    transform: scaleY(0);
    transform-origin: center bottom;
    animation: appearScreen cubic-bezier(0.19, 0.49, 0.44, 1.82) 0.25s forwards 1s;
}

.halo {
    color: $light-color;
    animation: animateText steps(5, end) 0.75s forwards 1.25s, blink 0.5s step-end infinite;
    border-right: solid 2px $light-color;
    font-size: 1.6rem;
    font-weight: 600;
    overflow: hidden;
    width: 0;
    letter-spacing: 4px;
    transform: translateY(10px);
}

@keyframes jumping {
    0% {
        transform: translateY(60%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes blink {
    0%, 100% {
        border-right-color: transparent;
    }
    50% {
        border-right-color: #fff;
    }
}

@keyframes animateText {
    0% {
        width: 0px; 
    }
    100% {
        width: 90px; 
    } 
}

.bottom-frame {
    width: 300px;
    height: 30px;
    background-color: $gray-color;
    border-radius: 0 0 15px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scaleX(0);
    animation: appearBtmFrame ease 0.25s forwards .75s;
}

.mac-logo {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: darken($gray-color, 10%);
}

.stand {
    width: 88px;
    height: 26px;
    background-color: $gray-color;
    transform: rotateX(120deg);
    border-top: solid 0px darken($gray-color, 10%);
    animation: appear cubic-bezier(0.19, 0.49, 0.44, 1.82) 0.25s forwards .5s, standShadow cubic-bezier(0.19, 0.49, 0.44, 1.82) 0.5s forwards .75s;
}

.based {
    height: 6px;
    width: 160px;
    background-color: lighten($gray-color, 10%);
    position: relative;
    border-radius: 4px 4px 0 0;
    opacity: 0;
    transform: rotate(90deg) scaleX(0.3) translate(0, 30px);
    animation: jump cubic-bezier(0.19, 0.49, 0.44, 1.82) 0.25s forwards 0.25s;
}

@keyframes jump {
    0% {
      opacity: 1;
      transform: rotate(90deg) scaleX(0.3) translate(0, 30px);
    }
    35% {
      opacity: 1;
      transform: rotate(0deg) scaleX(0.3) translate(0, -40px);
    }
    50% {
      opacity: 1;
      transform: rotate(0deg) scaleX(0.3) translate(0, 0);
    }
    85% {
      opacity: 1;
      transform: rotate(0deg) scaleX(0.3) translate(0, 0);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg) scaleX(1) translate(0, 0);
    } 
}

@keyframes jumpShadow {
    0% {
        opacity: 1;
        width: 20px;
    }
    35% {
        width: 40px;
    }
    50% {
        width: 40px;
    }
    85% {
        width: 40px;
    }
    100% {
        opacity: 1;
        width: 135px;
    }
}

@keyframes shadowBtm {
    0% {
      width: 135px;
    }
    100% {
      width: 100%;
    }
}

@keyframes appear {
    0% {
      opacity: 1;
      transform: rotateX(120deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(50deg);
    }
}

@keyframes standShadow {
    0% {
      border-width: 0;
    }
    100% {
      border-width: 10px;
    }
}

@keyframes appearBtmFrame {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
}

@keyframes appearScreen {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
}