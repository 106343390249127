.baby {
    .all-baby {
        width: 140px;
        height: 259px;
        display: block;
        position: relative;
        transition: all 0.75s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }

    .all-ear {
        position: absolute;
        width: 78px;
        display: block;
        margin: auto;
        left: 0;
        right: 0;
        top: 40px;

        .ear {
            width: 16px;
            height: 22px;
            background-color: #ecd4a0;
            border-radius: 50%;
            position: absolute; 
            
            &.ear-left {
                left: -5px;
                transform: rotate(-15deg);
            }

            &.ear-right {
                right: -5px;
                transform: rotate(15deg);
            }
        }
    }

    .full-face {
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        width: 98px;
        display: block;
        margin: auto;
        height: 60px;

        .all-eye {
            position: absolute;
            left: 27px;
            right: 27px;
            top: -7px;
            z-index: 2;

            .eye {
                width: 7px;
                height: 14px;
                background: #373737;
                position: absolute;
                border-radius: 50%;
                animation: blinkEye 5s ease infinite;

                &.eye-left {
                    left: 0;
                }

                &.eye-right {
                    right: 0;
                }
            }
        }

        .nose {
            width: 15px;
            height: 7px;
            background-color: #e3c176;
            position: absolute;
            bottom: 40px;
            display: block;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 50%;
            z-index: 2;
        }

        .pacifier {
            position: absolute;
            bottom: 34px;
            right: 54px;

            .pacifier-base {
                width: 24px;
                height: 17px;
                background: #7ecce2;
                border-radius: 50%;
                position: absolute;
                transform: rotate(-15deg);
                animation: pacifier 1.5s ease infinite;

                .pacifier-holder {
                    width: 16px;
                    height: 16px;
                    border: solid 4px #68a9bc;
                    box-sizing: border-box;
                    border-radius: 50%;
                    position: absolute;
                    top: 8px;
                    left: 5px;
                }
            }
        }
    }

    .head {
        position: absolute;
        z-index: 10;
        left: 0;
        right: 0;

        .face {
            height: 78px;
        }
    }

    .top {
        width: 78px;
        height: 78px;
        border-radius: 50%;
        display: block;
        margin: auto;
        position: relative;
        background-color: #ecd4a0;
    }

    .hair-all {
        width: 78px;
        height: 78px;
        border-radius: 50%;
        position: relative;
        
        .hair-1 {
            width: 78px;
            height: 78px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #373737;
            box-shadow: 5px 5px rgba(0, 0, 0, 0.1);

            &::before {
                content: '';
                width: 78px;
                height: 78px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #ecd4a0;
                display: block;
                margin: auto;
                position: absolute;
                left: 0;
                right: 0;
                top: 11px;
                box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
            }
        }

        .hair-3 {
            width: 78px;
            height: 78px;
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;

            &::before {
                content: "";
                width: 100px;
                height: 100px;
                background-color: #373737;
                display: inline-block;
                border-radius: 50%;
                position: absolute;
                top: -70px;
                left: -20px;
                right: 0;
                transform: rotate(-16deg);
                box-shadow: -6px 5px rgba(0, 0, 0, 0.1);
                z-index: 5;
            }
        }
    }

    .body {
        width: 135px;
        height: 245px;
        background: #d1c34b;
        border: solid 8px #eddf65;
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        display: block;
        margin: auto;
        box-shadow: 8px 8px rgba(0, 0, 0, 0.1);

        .body-extend {
            width: 105px;
            height: 140px;
            border-radius: 50%;
            background: #DF9767;
            position: absolute;
            top: 50px;
            left: 8px;
        }

        .cloth {
            &::before {
                content: "";
                width: 260px;
                height: 200px;
                background: #e2d45a;
                display: block;
                transform: rotate(35deg);
                position: absolute;
                top: 110px;
                left: -85px;
                box-shadow: 0 -4px rgba(0, 0, 0, 0.1);
            }

            &::after {
                content: "";
                width: 260px;
                height: 200px;
                background: #eddf65;
                display: block;
                transform: rotate(-35deg);
                position: absolute;
                top: 110px;
                right: -85px;
                box-shadow: 0 -4px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .buble {
        background: #FFFFFF;
        display: inline-block;
        padding: 30px 25px;
        position: absolute;
        z-index: 15;
        border-radius: 50%;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #373737;
        opacity: 0;
        top: -100px;
        left: -80px;
        transform: rotate(-90deg);
        transform-origin: bottom right;
        transition: all .25s ease;
        
        &.right-tip::after {
            right: 25px;
            bottom: -10px;
            content: "";
            width: 0;
            height: 0;
            border: solid 20px transparent;
            display: inline-block;
            position: absolute;
            border-top-color: #FFFFFF;
            border-right-color: #FFFFFF;
            z-index: 14;
            transform: rotate(-20deg);
        }

        &.left-tip::after {
            bottom: -10px;
            content: "";
            width: 0;
            height: 0;
            border: solid 10px transparent;
            display: inline-block;
            position: absolute;
            border-top-color: #fff;
            border-left-color: #fff;
            left: 25px;
            z-index: 14;
            transform: rotate(20deg);
        }
    }
}

.toy {
    border: solid 4px #E6E4E4;
    box-sizing: border-box;
    border-radius: 50%;
    transform: rotate(-25deg);
    box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    position: absolute;
    top: 80px;
    left: -100px;

    .toypart {
        width: 4px;
        border-radius: 50px;
        position: absolute;
        overflow: hidden;
        background: #6295F2;
        height: 20px;
        transition: all 1.5s cubic-bezier(0.1, 0.54, 0.43, 1.45);

        &:nth-child(1) {
            background-color: #61d83c;
            transform: rotate(-85deg);
            top: 7px;
            left: -4px;
            box-shadow: -3px 3px rgba(0, 0, 0, 0.1);
            transition: all 1.5s cubic-bezier(0.1, 0.54, 0.43, 1.45) 0.7s;
        }

        &:nth-child(2) {
            background-color: #d0eb53;
            transform: rotate(-33deg);
            top: -8px;
            left: 3px;
            box-shadow: 1px 5px rgba(0, 0, 0, 0.1);
            transition: all 1.5s cubic-bezier(0.1, 0.54, 0.43, 1.45) 0.8s;
        }

        &:nth-child(3) {
            background-color: #eb5353;
            transform: rotate(-159deg);
            top: -9px;
            right: 4px;
            box-shadow: -3px -4px rgba(0, 0, 0, 0.1);
            transition: all 1.5s cubic-bezier(0.1, 0.54, 0.43, 1.45) 0.9s;
        }

        &:nth-child(4) {
            background-color: #53d4eb;
            transform: rotate(-114deg);
            top: 7px;
            right: -1px;
            box-shadow: -3px 2px rgba(0, 0, 0, 0.1);
            transition: all 1.5s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1s;
        }
    }

    &::after {
        content: "";
        width: 6px;
        background: #E6E4E4;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -34px;
        border-radius: 50px;
        transition: all 0.5s ease 0s;
        box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
        height: 40px;
        opacity: 1;
        transition: all 1.5s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }
}

.ball {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ed8d58;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
    // animation: ball-bounce 1s ease;
    position: absolute;
    right: -80px;
    top: -20px;
}
  
.toy-car {
    width: 39px;
    height: 59px;
    background: #44c0db;
    border-radius: 5px;
    position: absolute;
    right: -100px;
    margin: auto;
    bottom: 0;
    transform: rotate(10deg);
    transform-origin: 700% 50%;
    transition: all 1s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    box-shadow: 5px 5px rgba(0, 0, 0, 0.1);

    .front-wheel, .rear-wheel {
        position: absolute;
        left: 0;
        right: 0;
    }

    .front-wheel {
        top: 8px;
    }

    .rear-wheel {
        bottom: 25px;
    }

    .wheel {
        width: 4px;
        height: 17px;
        background: #505050;
        position: absolute;
        box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
    }

    .left-wheel {
        left: -4px;
    }

    .right-wheel {
        right: -4px;
    }

    .front-lamp {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;

        .lamp {
            width: 9px;
            height: 3px;
            background: #ECEBEB;
            position: absolute;
            top: -3px;

            &.left-lamp {
                left: 3px;
            }

            &.right-lamp {
                right: 3px;
            }
        }
    }

    .rear-lamp {
        height: 2px;
        width: 28px;
        position: absolute;
        background: #f26666;
        margin: auto;
        left: 0;
        right: 0;
        bottom: -2px;
    }

    .window {
        position: absolute;
        left: 0;
        right: 0;
        perspective: 50;
        bottom: 0;
        top: 0;

        &::before, &::after {
            content: '';
        }

        &::before {
            width: 35px;
            height: 18px;
            background: #51cce6;
            display: block;
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            transform: rotateX(40deg);
        }

        &::after {
            width: 35px;
            height: 10px;
            background: #51cce6;
            display: block;
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            top: 40px;
            transform: rotateX(-35deg);
        }
    }
}

.baby-scene {
    .toy {
        width: 40px;
        height: 40px;
        opacity: 1;
        transition: all 0.5s cubic-bezier(0.1, 0.54, 0.43, 1.45) 0.5s;

        .toypart {
            height: 20px;
        }

        &::after {
            height: 40px;
            opacity: 1;
            transition: all 0.5s cubic-bezier(0.1, 0.54, 0.43, 1.45) 0.6s;
        }
    }

    .ball {
        animation: ball-bounce 1s ease;
        top: -25px;
        right: -75px;
    }

    .toy-car {
        transform: rotate(-25deg);
        transition: all 1s cubic-bezier(0.1, 0.54, 0.43, 1.45) 0.25;
    }
}

@keyframes blinkEye {
    0% {
      height: 14px;
      top: 0;
    }
    48% {
      height: 14px;
      top: 0;
    }
    49% {
      height: 0px;
      top: 14px;
    }
    50% {
      height: 14px;
      top: 0;
    }
    100% {
      height: 14px;
      top: 0;
    }
}

@keyframes pacifier {
    0% {
      top: 0;
    }
    10% {
      top: -2px;
    }
    20% {
      top: 0;
    }
    30% {
      top: -2px;
    }
    50% {
      top: 0;
    }
    100% {
      top: 0;
    }
}

@keyframes ball-bounce {
    0%, 50%, 100% {
      transform: scale(1);
    }
    25%, 75% {
      transform: scale(1.5);
    }
}

.scene-enter, .scene-exit, .scene-appear {
    .all-baby {
        transform: translateY(-100vh);
    }

    .ball {
        right: -50vh;
        top: -50vh;
        transition: all 1s ease;
    }

    .toy-car {
        transform: rotate(-150deg);
        transition: all 1s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }

    .toy {
        transform: rotate(0deg) scale(0);
        transition: all 0.5s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }

    .scene.baby {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        margin-top: -129.5px;
    }
}

.scene-enter-done {
    .scene.baby {
        position: relative;
    }

    .all-baby {
        transform: translateY(0);
    }

    .ball {
        right: -80px;
        top: -20px;
        animation: ball-bounce 1s ease;
        transition: all 1s ease;
    }

    .toy-car {
        transform: rotate(10deg);
        transition: all 1s cubic-bezier(0.1, 0.54, 0.43, 1.45) .25s;
    }

    .toy {
        transform: rotate(-25deg) scale(1);
        transition: all 0.5s cubic-bezier(0.1, 0.54, 0.43, 1.45) .5s;
    }
}

.buble[data-buble="punk"] {
    left: -110px;
    right: 80px;
}

.music-hover-active .buble[data-buble="punk"] {
    opacity: 1;
    transform: rotate(0deg);
}