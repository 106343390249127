@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Gentium+Book+Basic:400,700&display=swap');

$blue-color:        #388DF7;
$light-color:       #ffffff;
$dark-color:        #2f2f2f;
$green-color:       #2CEFC0;
$light-gray-color:  #A6A8B3;
$gray-color:        #E6EDEF;
$bg-color:          #F8F9FC;

$main-font: "Roboto","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
$main-font: "Open Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
$second-font: "Gentium Book Basic",Georgia,Cambria,"Times New Roman",Times,serif;

.porto {
    height: 100%;
    width: 100%;
    display: flex;
    // align-items: center;
    overflow: auto;
    justify-content: center;
    opacity: 1;
    position: relative;
    background-color: $bg-color;
    transition: all .25s ease;

    .chapter-title {
        align-items: center;
        justify-content: center;

        &.page-title {
            text-align: center;
            font-size: 40px;
            font-weight: 700;
            color: $light-gray-color;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
        }

        span {
            font-size: 22px;
            vertical-align: top;
            display: inline-block;
            border-bottom: solid 2px;
            margin-right: 8px;
        }
    }
}

.porto-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;

    li {
        transition: all .25s ease;

        img {
            max-width: 100%;
        }

        &:hover {
            a {
                transform: scale(1.05);
            }
        }

        a {
            display: inline-block;
            border: solid 10px #fff;
            transition: all .25s ease;
        }
        
    }

    &:hover {
        li:not(:hover) {
            a {
                transform: scale(.95);
                opacity: .75;
            }
        }
    }
}

.title-group {
    position: relative;
    margin-bottom: 64px;
    text-align: center;
    
    .bg-text {
        color: $green-color;
        text-align: center;
        font-size: 64px;
        font-weight: bold;
        display: inline-block;
        letter-spacing: 14px;
    }

    .chapter-title {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 18px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 30px;

        span {
            font-size: 22px;
            vertical-align: top;
            display: inline-block;
            border-bottom: solid 2px;
            margin-right: 12px;
        }
    }
}

.section-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 12px;
    margin-bottom: 32px;
}

.porto-item-title {
    font-weight: 500;
    text-align: center;
    margin-top: 16px;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 10px;
}

.work-example-block {
    margin-top: 88px;

    .work-example-list {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;

        li {
            // width: 25%;
            // flex-basis: 25%;
            // flex-grow: 0;
            // flex-shrink: 0;

            &:nth-child(odd) {
                // background-color: red;
            }

            &:nth-child(even) {
                // background-color: blue;
            }

            a {
                display: inline-block;
                transition: all .25s;

                img {
                    max-width: 100%;
                    height: auto;
                }

                &:hover {
                    transform: scale(.95);
                }
            }
            
        }

        &:hover {
            a:not(:hover) {
                opacity: .75;
            }
        }
    }
}

.porto-cage, .porto-item {
    transition: all .5s ease;
}

.porto-page-enter, .porto-page-appear {
    position: absolute;
    opacity: 0;

    > * {
        transform: translateY(20%);
    }
}

.porto-page-enter-done, .porto-page-appear-done {
    opacity: 1;
    
    > * {
        transform: translateY(0);
    }
}

.porto-cage {
    padding: 120px 0;
}

.porto-list-exit-active, .porto-list-exit-done, .porto-list-enter-active, .porto-list-enter {
    position: absolute;
    transform: translateY(-20%);
    opacity: 0;
}

.porto-view-exit-active, .porto-view-exit-done, .porto-view-enter-active, .porto-view-enter {
    position: absolute;
    transform: scale(0) translateY(-100%);
    opacity: 0;
    border-radius: 50%;
    background-color: $green-color;
}

.porto-list-enter-done {
    position: relative;
    transform: translateY(0%);
    opacity: 1;
}

.porto-view-enter-done {
    position: relative;
    transform: scale(1) translateY(0);
    opacity: 1;
    border-radius: 0;
    background-color: transparent;
    transition: all .25s ease, background-color .5s ease .45s;
}

.porto-item {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;

    &.rentnow {
        // background-color:$green-color;
    }

    > {
        * {
            transform: translateY(80px);
            opacity: 0;
            transition: all .25s ease;
        }
    }

    &.porto-view-enter-done {
        > {
            * {
                transform: translateY(0px);
                opacity: 1;
                transition-delay: .55s;
            }
        }
    }

}

@media screen and (min-width: 1024px) {

}