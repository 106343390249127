@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Gentium+Book+Basic:400,700&display=swap');

$blue-color:        #388DF7;
$light-color:       #ffffff;
$dark-color:        #2f2f2f;
$green-color:       #2CEFC0;
$light-gray-color:  #A6A8B3;
$gray-color:        #E6EDEF;

$main-font: "Roboto","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
$main-font: "Open Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
$second-font: "Gentium Book Basic",Georgia,Cambria,"Times New Roman",Times,serif;

.rent-now-cage {
    padding-top: 160px;
    width: 100%;

    .porto-title {
        font-size: 32px;
        font-weight: 600;
        letter-spacing: 6px;
        margin-bottom: 56px;
    }
}

.table-content {
    display: flex;
    max-width: 872px;
    width: 90%;
    width: 100%;
    margin: auto;
    align-items: center;
    flex-wrap: wrap;

    .left-icon-group, .right-icon-group {
        display: flex;
        flex-direction: column;

        a + a {
            margin-top: 16px;
        }

        a {
            &:hover {
                svg {
                    path {
                        fill: $dark-color;
                    }
                }
            }
        }

        svg {
            path {
                fill: lighten($dark-color, 50%);
                transition: all .25s ease;
            }
        }
    }

    .data-table {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        
        
        margin: 0 24px;
        margin: 0 16px;
        
        .dt-group {
            flex-grow: 1;
            display: flex;

            &.dir-col {
                flex-direction: column;
            }

            .dt-item {
                // border-left: none;
                border-top: none;

                &.dt-data {
                    flex-grow: 1;
                }
            }

            .dt-group {
                .dt-item {
                    border-top: none;

                    &:not(:first-child) {
                        // border-left: none;
                    }
                }
            }
        }

        .dt-item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 24px;
            border: solid 2px lighten($dark-color, 70%);
            color: lighten($dark-color, 30%);

            &:not(:first-child) {
                // border-left: none;
                border-top: none;
            }

            .link-data {
                color: lighten($dark-color, 30%);
                text-decoration: none;
                border-bottom: solid 1px lighten($dark-color, 50%);
                display: inline-block;
                margin-right: 6px;
                transition: all .25s ease;

                &:hover {
                    opacity: .5;
                }
            }
        }

        .dt-label {
            span {
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 2px;
                border-bottom: solid 2px lighten($dark-color, 30%);
                padding-bottom: 4px;
            }
        }

        .dt-data {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 2px;
        }
    }
}

.main-content {
    max-width: 640px;
    width: 90%;
    margin: 64px auto 0;
    padding-bottom: 64px;

    img {
        width: 100%;

        &:not(:first-child) {
            margin-top: -4px;
        }
    }

    .content-cover {
        margin-bottom: 64px;
    }

    .text-group {
        max-width: 480px;
        width: 90%;
        margin: auto;

        & + .text-group {
            margin-top: 64px;
        }

        & + img {
            margin-top: 32px;
        }
    }

    img:not(.content-cover) {
        & + .text-group {
            margin-top: 64px;
        }
    }

    .content-title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 4px;
        margin-bottom: 24px;
    }

    .content-text {
        font-size: 16px;
        text-align: left;
        line-height: 1.8;
        color: lighten($dark-color, 20%);

        & + .content-text {
            margin-top: 16px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                &::before {
                    content: '-';
                    display: inline-block;
                    margin-right: 16px;
                }
            }
        }
    }

    .img-box {
        span {
            margin-top: 16px;
            display: inline-block;
            font-size: 16px;
            color: lighten($dark-color, 20%);
        }

        & + .img-box {
            margin-top: 32px;
        }

        & + .text-group {
            margin-top: 64px;
        }
    }
}

.scrollTop {
    position: fixed; 
    width: 100%;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
  }
  
  .scrollTop:hover{
    opacity: 1;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

.footer-menu {
    margin-top: 80px;
    
    .portfolio-menu-group {
        display: flex;
        flex-direction: column;

        .portfolio-menu-item {
            flex-direction: column;
            display: flex;
            padding: 24px;
            background-color: transparentize(#000, .99);
            
            align-items: center;
            text-decoration: none;
            color: $dark-color;
            transition: all .25s ease;
            cursor: pointer;

            &:last-child {
                background-color: transparentize(#000, .97);
            }
            

            &:hover {
                background-color: lighten($green-color, 30%);

                .text-box {
                    transform: translateX(10px);
                }
            }

            .img-box {
                width: 80px;
                border: solid 4px $light-color;
                margin-bottom: 24px;

                img {
                    width: 100%;
                }
            }

            .text-box {
                padding: 0 16px;
                transition: all .5s ease;

                .footer-menu-title {
                    font-size: 18px;
                    margin-bottom: 8px;
                }

                .footer-text {
                    font-size: 14px;
                }
            }

        }
    }
}

.main-menu-bar {
    background-color: $green-color;
    padding: 24px;
    position: relative;
    
    ul {
        list-style: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
            

            a {
                text-decoration: none;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 2px;
                color: darken($green-color, 30%);
                transition: all .25s ease;

                &:hover {
                    color: darken($green-color, 60%);;
                }
            }
        }
    }

    .scroll-top {
        transition: all .5s ease;
        display: block;
        padding: 24px 0 8px;

        &:hover {
            transform: translateY(-4px);

            svg {
                path {
                    fill: darken($green-color, 60%);
                }
            }
        }

        svg {
            width: 16px;
            height: auto;

            path {
                fill: darken($green-color, 30%);
                transition: all .25s ease;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .main-menu-bar {
        ul {
            li {
                padding: 8px;
                flex: 0 0 100%;
                
                a {

                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .table-content {
        .data-table {
            order: 1;
        }
        
        .left-icon-group, .right-icon-group  {
            flex: 0 0 50%;
            padding-top: 24px;
        }

        .left-icon-group {
            order: 2;
        }

        .right-icon-group {
            order: 3;
        }
    }

    .table-content {
        .data-table {
            .dt-group {
                flex-direction: column;
            }
        }
    }
}

@media screen and (min-width: 426px) {
    .main-menu-bar {
        ul {
            li {
                padding: 8px;
                flex: 0 0 50%;
                
                a {

                }
            }
        }
    }
}

@media screen and (min-width: 769px) {
    .footer-menu {
        .portfolio-menu-group {
            flex-direction: row;

            .portfolio-menu-item {
                flex: 0 0 50%;
                text-align: left;
                flex-direction: row;

                &:last-child {
                    text-align: right;
                    flex-direction: row-reverse;
    
                    &:hover {
                        .text-box {
                            transform: translateX(-10px);
                        }
                    }
                }

                .img-box {
                    margin-bottom: 0;
                }
            }
        }       
    }

    .main-menu-bar {
        ul {
            li {
                margin: 0 24px;
                flex: 0 0 auto;
                
                a {

                }
            }
        }

        .scroll-top {
            position: absolute;
            right: 24px;
            top: 50%;
            margin-top: -8px;
            display: inline-block;
            padding: 0;
        }
    }

    .table-content {
        .data-table {
            .dt-group {
                .dt-group {
                    .dt-item {
                        &:not(:first-child) {
                            border-left: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    .table-content {
        .data-table {
            flex-direction: row;
            
            .dt-item:not(:first-child) {
                border-left: none;
                border-top: solid 2px #e2e2e2;
            }

            .dt-group {
                .dt-item {
                    border-left: none;
                    border-top: solid 2px #e2e2e2;
                }
                .dt-group {
                    .dt-item {
                        
                    }
                }
            }
        }
    }
}