$blue-color:        #388DF7;
$light-color:       #ffffff;
$dark-color:        #2f2f2f;
$bg-color:       #29D08A;
$light-gray-color:  #A6A8B3;
$gray-color:        #E6EDEF;
$bg-color:          #F3F7FB;

.grown {

    .buble {
        background: #FFFFFF;
        display: inline-block;
        padding: 30px 25px;
        position: absolute;
        z-index: 15;
        border-radius: 50%;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #373737;
        opacity: 0;
        top: -80px;
        left: -40px;
        transform: rotate(-90deg);
        transform-origin: bottom right;
        transition: all .25s ease;
        
        &.right-tip::after {
            right: 25px;
            bottom: -10px;
            content: "";
            width: 0;
            height: 0;
            border: solid 20px transparent;
            display: inline-block;
            position: absolute;
            border-top-color: #FFFFFF;
            border-right-color: #FFFFFF;
            z-index: 14;
            transform: rotate(-20deg);
        }

        &.left-tip::after {
            bottom: -10px;
            content: "";
            width: 0;
            height: 0;
            border: solid 10px transparent;
            display: inline-block;
            position: absolute;
            border-top-color: #fff;
            border-left-color: #fff;
            left: 25px;
            z-index: 14;
            transform: rotate(20deg);
        }
    }

    .all-kid {
        width: 280px;
        height: 363px;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }

    .head {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
    }

    .face {
        height: 121px;
        position: relative;
    }

    .all-ear {
        position: absolute;
        width: 119px;
        display: block;
        margin: auto;
        left: 0;
        right: 0;
        top: 75px;

        .ear {
            width: 16px;
            height: 22px;
            background: #ecd4a0;
            border-radius: 50%;
            position: absolute;

            &.ear-left {
                left: -2px;
                transform: rotate(-15deg);
            }

            &.ear-right {
                right: -2px;
                transform: rotate(15deg);
            }
        }
    }

    .top {
        width: 119px;
        height: 112px;
        border-radius: 50%;
        display: block;
        margin: auto;
        position: relative;
    }

    .hair-all {
        width: 119px;
        height: 112px;
        border-radius: 50%;
        position: relative;
    }

    .hair-tip-1 {
        width: 40px;
        height: 40px;
        background: #373737;
        position: absolute;
        border-radius: 50%;
        top: 21px;
        left: -6px;
        transform: rotate(-20deg);

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 136px;
            height: 38px;
            background: $bg-color;
            border-radius: 50%;
            top: -28px;
            transform: rotate(-15deg);
        }
    }

    .hair-tip-2 {
        width: 40px;
        height: 40px;
        background: #373737;
        position: absolute;
        border-radius: 50%;
        top: 2px;
        left: -5px;
        transform: rotate(-8deg);

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 136px;
            height: 38px;
            background: $bg-color;
            border-radius: 50%;
            top: -28px;
            transform: rotate(-15deg);
        }
    }

    .hair-1 {
        width: 119px;
        height: 112px;
        border-radius: 50%;
        background: #373737;
        position: relative;
        
        &::before {
            content: '';
            width: 108px;
            height: 114px;
            border-radius: 50%;
            background-color: #ecd4a0;
            display: block;
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            top: 12px;
        }
    }

    .hair-3 {
        width: 119px;
        height: 112px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;

        &::before {
            content: "";
            width: 119px;
            height: 73px;
            background-color: #373737;
            display: inline-block;
            border-radius: 50%;
            position: absolute;
            top: -12px;
            left: -15px;
            right: 0;
            transform: rotate(-16deg);
            box-shadow: -6px 5px rgba(0, 0, 0, 0.1);
            z-index: 5;
        }

        &::after {
            content: "";
            width: 98px;
            height: 73px;
            background: #373737;
            display: inline-block;
            border-radius: 50%;
            position: absolute;
            transform: rotate(30deg);
            right: -50px;
            top: -15px;
            box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
            z-index: 4;
        }
    }

    .full-face {
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        width: 108px;
        display: block;
        margin: auto;
        height: 95px;

        .all-eye {
            position: absolute;
            left: 25px;
            right: 25px;
            top: 15px;
            z-index: 2;

            .eye {
                width: 12px;
                height: 21px;
                background: #373737;
                position: absolute;
                border-radius: 50%;
                animation: blinkEye1 5s ease infinite;

                &.eye-left {
                    left: 0;
                }

                &.eye-right {
                    right: 0;
                }
            }
        }

        .nose {
            width: 15px;
            height: 7px;
            background-color: #e3c176;
            position: absolute;
            bottom: 49px;
            display: block;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 50%;
            z-index: 2;
        }

        .mouth {
            width: 28px;
            height: 37px;
            background: #373737;
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 28px;
            border-radius: 50%;

            &::after {
                content: "";
                width: 79px;
                height: 44px;
                background: #ecd4a0;
                display: block;
                position: absolute;
                left: -93%;
                right: 0;
                margin: auto;
                border-radius: 50%;
                bottom: 5px;
            }

            &.talk:after {
                animation: talking 0.5s ease 4;
            }
        }
    }

    .neck {
        width: 25px;
        height: 14px;
        background-color: #e9cc8f;
        margin: auto;
        position: absolute;
        top: 123px;
        left: 0;
        right: 0;
        
        ::before {
            content: "";
            background-color: #e7c887;
            width: 25px;
            height: 7px;
            position: absolute;
            top: 1px;
            left: 0;
            right: 0;
            border-radius: 50%;
          }
    }

    .all-body {
        position: absolute;
        height: auto;
        margin: auto;
        width: 87px;
        left: 0;
        right: 0;
        top: 137px;
        
        .all-hand {
            position: absolute;
            left: 0;
            right: 0;
            top: -1px;
            
            .hand {
                width: 21px;
                height: 52px;
                position: absolute;
                
                &.hand-left {
                    left: -20px;
                    border-radius: 50% 0 0 0;
                    transform: rotate(15deg);
                }

                &.hand-right {
                    right: -20px;
                    border-radius: 0 50% 0 0;
                    transform: rotate(-15deg);
                }
            }
        }
    
        .body {
            display: inline-block;
            width: 87px;
            height: 101px;
            margin: auto;
            position: relative;
        }

        .neck-tip {
            width: 25px;
            height: 7px;
            background: #E6CF9E;
            margin: auto;
            border-radius: 0 0 50% 50%;
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            top: -7px;
        }
    }

    .all-colar {
        position: absolute;
        top: -4px;
        left: 0;
        right: 0;
        
        .colar {
            width: 21px;
            height: 12px;
            background-color: #e2d4d4;
            background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.5) 50%, transparent 50%), linear-gradient(rgba(200, 0, 0, 0.5) 50%, transparent 50%);
            background-size: 15px 15px;
            background-position: top center;
            position: absolute;
            top: 0;
            z-index: 3;
            
            &.colar-left {
                transform: rotate(45deg);
                left: 22px;
            }

            &.colar-right {
                transform: rotate(-45deg);
                right: 22px;
            }
        }
    }

    .foot {
        position: absolute;
        left: 0;
        margin: auto;
        right: 0;
        top: 248px;
        width: 92px;
        z-index: 2;
    }
    .butt {
        width: 92px;
        height: 28px;
        background: #2C3E4B;
        margin: auto;
    }
    .all-legs {
        position: absolute;
        left: 0;
        right: 0;
        top: 25px;

        .legs {
            position: absolute;
            height: 77px;
            width: 31px;
            background: #375063;
            
            &.leg-left {
                left: 0;
            }

            &.leg-right {
                right: 0;
            }

            &::before {
                content: "";
                width: 31px;
                height: 31px;
                background: #375063;
                position: absolute;
                display: block;
                top: -20px;
                border-radius: 50%;
            }

            .shoes {
                position: absolute;
                left: 0;
                right: 0;
                bottom: -12px;
                height: 19px;
                
                .shoe-top {
                    width: 19px;
                    height: 19px;
                    background: #272626;
                    margin: auto;
                    position: absolute;
                    left: 0;
                    right: 0;

                    &::before {
                        content: "";
                        width: 13px;
                        height: 17px;
                        background: #363636;
                        display: block;
                        position: absolute;
                        left: -9px;
                        bottom: 0;
                    }

                    &::after {
                        content: "";
                        width: 13px;
                        height: 17px;
                        background: #363636;
                        display: block;
                        position: absolute;
                        right: -9px;
                        bottom: 0;
                    }
                }
    
                .shoe-bottom {
                    width: 32px;
                    height: 12px;
                    background: #fff;
                    display: block;
                    position: absolute;
                    border-radius: 50%;
                    left: -1px;
                    right: 0;
                    margin: auto;
                    bottom: 0;

                    &::after {
                        content: "";
                        width: 37px;
                        height: 6px;
                        background: #dddddd;
                        display: block;
                        position: absolute;
                        left: -2px;
                        right: 0;
                        margin: auto;
                        bottom: 0;
                    }
                } 
                
                .shoe-lace {
                    width: 26px;
                    height: 2px;
                    background: #fff;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: 5px;
                  }
            }
        }
    }
    
    .chair {
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        margin: auto;
        width: 140px;
        top: 207px;
        z-index: 1;
        
        .chair-top {
            width: 119px;
            height: 70px;
            border-left: solid 7px #ddd;
            border-right: solid 7px #ddd;
            margin: auto;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            right: 0;
        }

        .chair-bottom {
            width: 135px;
            height: 85px;
            border-left: solid 12px #dddddd;
            border-right: solid 12px #dddddd;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 68px;

            &::after {
                content: "";
                width: 140px;
                height: 7px;
                background: #902626;
                display: block;
                position: absolute;
                margin: auto;
                left: -25px;
                right: -25px;
                top: 0;
            }
        }
    }

    .body {
        background-color: white;
        background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.5) 50%, transparent 50%), linear-gradient(rgba(200, 0, 0, 0.5) 50%, transparent 50%);
        background-size: 15px 15px;
        background-position: top center;
    }
      .hand, .arm {
        background-color: white;
        background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.5) 50%, transparent 50%), linear-gradient(rgba(200, 0, 0, 0.5) 50%, transparent 50%);
        background-size: 15px 15px;
        background-position: top left;
    }            
}

.laptop {
    position: absolute;
    left: 0;
    right: 0;
    top: 162px;
    z-index: 2;
    perspective: 500px;

    .laptop-screen {
        width: 165px;
        height: 77px;
        background: #e3e3e6;
        margin: auto;
        border-radius: 5px 5px 0 0;
        text-align: center;
        padding: 26px 0;
        box-sizing: border-box;
        transform: rotateX(-30deg);
        
        .logos {
            width: 24px;
            height: 24px;
            background: #fff;
            border-radius: 50%;
            display: inline-block;
            position: relative;

            &::after {
                content: "";
                width: 16px;
                position: absolute;
                left: 0;
                right: 0;
                display: block;
                margin: auto;
                height: 14px;
                background: #fff;
                bottom: -3px;
            }

            .eye-skull {
                position: absolute;
                display: block;
                left: 0;
                right: 0;
                margin: auto;
                top: 10px;

                &::before, &::after {
                    content: "";
                    width: 7px;
                    height: 9px;
                    background: #e3e3e6;
                    display: block;
                    position: absolute;
                    border-radius: 50%;
                    z-index: 5;
                }

                &::before {
                    left: 4px;
                }

                &::after {
                    right: 4px;
                }
            }
        }
    }

    .base {
        width: 156px;
        height: 7px;
        background: #D4D2D0;
        margin: auto;
        margin-top: -8px;
        box-sizing: border-box;
    }
}
    
.table {
    position: absolute;
    left: 0;
    right: 0;
    top: 238px;
    z-index: 3;
    width: 280px;
    margin: auto;

    .top-table {
        width: 280px;
        height: 10px;
        background: #3e342d;
        margin: auto;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }

    .bottom-table {
        width: 266px;
        height: 115px;
        margin: auto;
        border-left: solid 10px #4d4139;
        border-right: solid 10px #4d4139;
        box-sizing: border-box;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }
}

.big-shadow {
    width: 280px;
    height: 19px;
    background-color: darken($bg-color, 5%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    border-radius: 50%;
    margin: auto;
    -webkit-transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    -moz-transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    -ms-transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    -o-transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
}

.buttons {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    margin-top: 7px;

    li {
        width: 6px;
        height: 6px;
        background: #464646;
        border-radius: 50%;
        display: block;
        margin: auto;
        margin-bottom: 15px;
      }
}

.mug {
    right: 10px;
    height: 11px;
    background-color: #3e4b56;
    width: 23px;
    position: absolute;
    top: -11px;
    transition: all .2s cubic-bezier(0.1,.54,.43,1.45);

    &::before {
        content: '';
        height: 25px;
        background-color: #44535F;
        transition: all .2s cubic-bezier(0.1,.54,.43,1.45) .8s;
        width: 28px;
        position: absolute;
        top: -24px;
        right: -2px;
    }

    &::after {
        content: '';
        width: 17px;
        height: 16px;
        border: solid 4px transparent;
        position: absolute;
        border-radius: 50%;
        top: -19px;
        right: -13px;
        transition: all .2s cubic-bezier(0.1,.54,.43,1.45);
        border: solid 4px #44535F;
    }
}

.desk-lamp {
    top: 0;
    position: absolute;
    left: 65px;
    width: 37px;

    .lamp-arm {
        width: 3px;
        height: 56px;
        background: #dedddc;
        position: absolute;
        bottom: 2px;
        left: -36px;
        transform-origin: bottom;
        display: block;
        transform: rotate(-45deg);
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);

        &::before {
            content: '';
            width: 3px;
            height: 56px;
            background: #dedddc;
            position: absolute;
            bottom: 52px;
            left: 0px;
            display: block;
            transform-origin: bottom;
            transform: rotate(90deg);
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
        }

        &::after {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background: #e3e3e6;
            border-radius: 50%;
            top: -5px;
            left: -8px;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
        }
    }

    .lamp-base {
        width: 37px;
        height: 7px;
        background: #e3e3e6;
        position: absolute;
        left: -55px;
        bottom: 0;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
    }

    .lamp {
        width: 21px;
        height: 21px;
        position: absolute;
        background: #ffe40d;
        top: -81px;
        border-radius: 50%;
        left: -40px;
        transform: rotate(-135deg);
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);

        &::after {
            content: "";
            width: 24px;
            height: 35px;
            position: absolute;
            background: #e3e3e6;
            top: -7px;
            border-radius: 0 50% 50% 0;
            right: -12px;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);
        }
    }
}

.clock2 {
    width: 125px;
    height: 125px;
    border: solid 7px darken($bg-color, 5%);
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    top: -190px;
    transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);

    &::before, &::after {
        content: '';
    }

    &::before {
        width: 95px;
        height: 2px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-right: solid 60px darken($bg-color, 5%);
        animation: clock 60s steps(60) infinite;
    }

    &::after {
        width: 4px;
        height: 70px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-top: solid 45px darken($bg-color, 5%);
        animation: clock 3600s steps(60) infinite;
    }
}

.windows {
    width: 189px;
    height: 207px;
    background-color: darken($bg-color, 5%);
    position: absolute;
    top: -40px;
    right: -150px;
    transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45);

    &::before, &::after {
        content: "";
        position: absolute;
    }

    &::before {
        width: 100%;
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: $bg-color;
        height: 10px;
    }

    &::after {
        width: 10px;
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: $bg-color;
        height: 100%;
    }
}

.book-shelf-2 {
    width: 175px;
    height: 7px;
    background-color: darken($bg-color, 5%);
    position: absolute;
    left: -160px;
    top: 90px;
    transition: all .2s cubic-bezier(0.1,.54,.43,1.45);

    .verti-book {
        right: 28px;
    }
}

.box-book {
    width: 60px;
    height: 36px;
    background-color: darken($bg-color, 5%);
    position: absolute;
    top: -36px;
    left: 10px;

    &::before {
        content: '';
        width: 72px;
        height: 11px;
        background-color: darken($bg-color, 5%);
        position: absolute;
        top: -11px;
        left: -6px;
    }
}
     
.diagon-book {
    height: 68px;
    width: 16px;
    background-color: darken($bg-color, 5%);
    position: absolute;
    bottom: 5px;
    left: 86px;
    transform: rotate(30deg);
}

.verti-book {
    height: 85px;
    width: 16px;
    background-color: darken($bg-color, 5%);
    position: absolute;
    top: -85px;
    &::after {
        content: "";
        height: 68px;
        width: 16px;
        background-color: darken($bg-color, 5%);
        position: absolute;
        bottom: 0;
        right: -20px;
    }
}

@keyframes blinkEye1 {
    0% {
      height: 21px;
      top: 0;
    }
    48% {
      height: 21px;
      top: 0;
    }
    49% {
      height: 0px;
      top: 21px;
    }
    50% {
      height: 21px;
      top: 0;
    }
    100% {
      height: 21px;
      top: 0;
    }
}

@keyframes talking {
    0% {
      bottom: 5px;
    }
    50% {
      bottom: 10px;
    }
    100% {
      bottom: 5px;
    }
}

@keyframes clock {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(450deg);
    }
}

%transformGrowUpStart {
    transform: scaleY(0);
    transform-origin: bottom;
}

%transformGrowUpEnd {
    transform: scaleY(1);
    transform-origin: bottom;
}

.about-page-enter, .about-page-exit, .about-page-appear {
    .scene.grown {
        transform: translateX(75vh) scale(.5);
        transition: all .5s cubic-bezier(0.15, 0.12, 0.12, 1.93);
    }

    .clock2 {
        transform: scale(0);
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .5s;
    }

    .book-shelf-2 {
        transform: scaleX(0);
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .7s;
    }

    .box-book {
        @extend %transformGrowUpStart;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .9s;

        &::before {
            transform: scaleY(0);
            transform-origin: bottom;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.1s;
        }
    }

    .verti-book {
        @extend %transformGrowUpStart;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.3s;

        &::after {
            transform: scaleY(0);
            transform-origin: bottom;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.5s;
        }
    }

    .diagon-book {
        transform: rotate(0deg) scaleY(0);
        transform-origin: bottom;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.7s;
    }

    .windows {
        transform: translateY(100px);
        opacity: 0;
        transition: all .5s cubic-bezier(0.15, 0.12, 0.12, 1.93) 1.1s;
    }

    .mug {
        @extend %transformGrowUpStart;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .7s;

        &::before {
            transform: scaleY(0);
            transform-origin: bottom;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .9s;
        }

        &::after {
            transform: scaleX(0);
            transform-origin: left center;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.1s;
        }
    }

    .desk-lamp {
        .lamp-base {
            @extend %transformGrowUpStart;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.3s;
        }

        .lamp-arm {
            transform: rotate(0deg) scaleY(0);
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.5s;

            &::after {
                transform: scale(0);
                transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.7s;
            }

            &::before {
                transform: rotate(180deg) scaleY(0);
                transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.9s;
            }
        }

        .lamp {
            transform: rotate(-135deg) scale(0);
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 2.1s;
        }
    } 
}

.about-page-enter-done, .about-page-appear-done {
    .scene.grown {
        transform: translateX(0) scale(.5);
        transition: all .5s cubic-bezier(0.15, 0.12, 0.12, 1.93);
    }

    .clock2 {
        transform: scale(1);
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .5s;
    }

    .book-shelf-2 {
        transform: scaleX(1);
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .7s;
    }

    .box-book {
        @extend %transformGrowUpEnd;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .9s;

        &::before {
            transform: scaleY(1);
            transform-origin: bottom;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.1s;
        }
    }

    .verti-book {
        @extend %transformGrowUpEnd;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.3s;

        &::after {
            transform: scaleY(1);
            transform-origin: bottom;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.5s;
        }
    }

    .diagon-book {
        transform: rotate(30deg) scaleY(1);
        transform-origin: bottom;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.7s;
    }

    .windows {
        transform: translateY(0);
        opacity: 1;
        transition: all .5s cubic-bezier(0.15, 0.12, 0.12, 1.93) 1.1s;
    }

    .mug {
        @extend %transformGrowUpEnd;
        transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .7s;

        &::before {
            transform: scaleY(1);
            transform-origin: bottom;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) .9s;
        }

        &::after {
            transform: scaleX(1);
            transform-origin: left center;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.1s;
        }
    }

    .desk-lamp {
        .lamp-base {
            @extend %transformGrowUpEnd;
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.3s;
        }

        .lamp-arm {
            transform: rotate(-45deg) scaleY(1);
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.5s;

            &::after {
                transform: scale(1);
                transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.7s;
            }

            &::before {
                transform: rotate(90deg) scaleY(1);
                transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 1.9s;
            }
        }

        .lamp {
            transform: rotate(-135deg) scale(1);
            transition: all 0.2s cubic-bezier(0.1, 0.54, 0.43, 1.45) 2.1s;
        }
    }
}

.name-hover-active, .clicker-hover-active {
    &.scene.grown {
        .mouth::after {
            animation: talking 0.5s ease 4;
        }
    }
}

.name-hover-active {
    .buble[data-buble="dion"] {
        opacity: 1;
        transform: rotate(0deg);
    }
}

.clicker-hover-active {
    .buble[data-buble="clicker"] {
        opacity: 1;
        transform: rotate(0deg);
    }
}

@media screen and (min-width: 1025px) {
    .about-page-enter, .about-page-exit, .about-page-appear {
        .scene.grown {
            transform: translateX(75vh) scale(.7);
        }
    }
    .about-page-enter-done, .about-page-appear-done {
        .scene.grown {
            transform: translateX(0) scale(.7);
        }
    }    
}