$light-color: #ffffff;

.text-box {
    // text-align: center;
    // animation: jumping cubic-bezier(0.19, 0.49, 0.44, 1.82) 0.25s forwards 1.5s, fadeIn ease .25s forwards 1.5s;
    // transform: translateY(60%);
    // opacity: 0;
}

@keyframes jumping {
    0% {
        transform: translateY(60%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mac + .text-box {
    margin-top: 56px;
}

.text-box p {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1px;
}

.text-box a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.text-box p + p {
    margin-top: 8px;
    line-height: 1.5;
    max-width: 500px;
    font-size: 20px;
}

.short-cut {
    padding: 16px 0;
    display: flex;
    width: 100%;
    border-top: dashed 1px $light-color;
    border-bottom: dashed 1px $light-color;
    margin-top: 24px;
    justify-content: space-around;
    align-items: center;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.short-cut a {
    text-transform: uppercase;
    font-size: 12px;
    transition: all .25s ease;
    font-weight: 600;
    letter-spacing: 2px;
}

.short-cut a:hover {
    opacity: .5;
}

.short-cut .dot-separator {
    width: 8px;
    height: 8px;
    background-color: $light-color;
    display: inline-block;
    border-radius: 50%;
}